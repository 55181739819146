<template>
  <div style="width: 100%">
    <div class="d-flex flex-row" style="width: 100%" v-if="_id">
      <editable-avatar
        :image="profile.thumbnail ? profile.thumbnail : profile.image"
        :editable="false"
        :size="$vuetify.breakpoint.xs ? 30 : 40"
        class="mr-3"
      />
      <div style="width: 100%">
        <div class="d-flex flex-column comment-section">
          <div class="d-flex flex-row">
            <v-textarea
              v-model="text"
              placeholder="Add a comment..."
              rows="1"
              auto-grow
              dense
              flat
              solo
              hide-details
              style="width: 100; border-radius: 20px"
              ref="comment"
            />

            <v-menu
              bottom
              offset-y
              origin="bottom bottom"
              :close-on-content-click="false"
              v-model="emojiKeyboard"
            >
              <template v-slot:activator="{ on: menu, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="{ ...menu }"
                  v-if="$vuetify.breakpoint.xs"
                >
                  <v-icon v-text="'mdi-emoticon-happy-outline'" />
                </v-btn>
                <v-tooltip top v-else>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                      <v-icon v-text="'mdi-emoticon-happy-outline'" />
                    </v-btn>
                  </template>
                  <span>Open Emoji Keyboard</span>
                </v-tooltip>
              </template> 
              <v-card>
                <VEmojiPicker @select="selectEmoji" />
              </v-card>
            </v-menu>
            <v-btn icon @click="onSelectImage">
              <v-icon v-text="'mdi-image-area'" />
            </v-btn>
          </div>
          <div
            style="width: 100%; background-color:#f9fafb; border-radius: 0 0 20px 20px"
            v-if="selectedFile"
          >
            <v-divider />
            <div style="position:relative; width: 220px; border-radius: 20px">
              <v-img
                :src="selectedImage"
                alt="image attachment"
                max-width="200px"
                width="200px"
                class="ma-3 rounded-lg"
                contain
              />
              <v-btn
                @click="onRemoveImage"
                class="remove_button"
                fab
                x-small
                color="primary"
              >
                <v-icon v-text="'mdi-close'" size="16" />
              </v-btn>
            </div>
          </div>
        </div>
        <v-btn
          color="primary"
          rounded
          class="mt-3"
          v-if="text || selectedFile"
          @click="onComment"
          :loading="loading"
        >
          Post
        </v-btn>
      </div>
      <input
        ref="uploader"
        class="d-none"
        type="file"
        accept="image/*"
        @change="onFileChanged"
      />
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </div>
    <event-comment-item
      :comment="comment"
      :onUpdatedComment="onUpdatedComment"
      :onDeletedComment="onDeletedComment"
      v-for="comment in comments"
      :key="comment._id"
      class="mt-5"
    />
    <v-btn
      text
      class="mt-3"
      small
      style="color: grey"
      :loading="loading"
      @click="loadMoreCommentsAction"
      v-if="total > comments.length"
    >
      Load more comments
    </v-btn>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import EditableAvatar from "@/components/EditableAvatar.vue";
import EventCommentItem from "./EventCommentItem.vue";
export default {
  components: { EditableAvatar, EventCommentItem },
  props: {
    _id: String,
    onPosted: {
      type: Function
    },
    total: {
      type: Number,
      default: 0
    },
    commentId: String
  },
  data() {
    return {
      text: "",
      selectedFile: null,
      selectedImage: "",
      emojiKeyboard: false,
      loading: false,
      errorMessage: null,
      snackbar: false,
      comments: []
    };
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      profileType: "type"
    })
  },
  methods: {
    ...mapActions("event", {
      setEventCommentLoaded: "setEventCommentLoaded",
      postComment: "comment",
      getComments: "getComments",
      loadMoreComments: "loadMoreComments"
    }),
    selectEmoji(emoji) {
      this.insertText(emoji.data);
      this.makeFocus();
    },
    insertText(text) {
      const el = this.$refs.comment.$refs.input;
      let cursorPos = el.selectionEnd;
      this.text =
        this.text.substring(0, cursorPos) +
        text +
        this.text.substring(cursorPos);
      cursorPos += text.length;
      this.$nextTick(() => el.setSelectionRange(cursorPos, cursorPos));
      // this.emojiKeyboard = false;
    },
    onFileChanged(e) {
      if (e.target.files.length != 0) {
        this.selectedFile = e.target.files[0];
        this.selectedImage = URL.createObjectURL(this.selectedFile);
      }
    },
    onSelectImage() {
      this.isSelectingPhoto = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingPhoto = false;
        },
        { once: true }
      );
      this.$refs.uploader.value = null;
      this.$refs.uploader.click();
    },
    onRemoveImage() {
      this.selectedFile = null;
      this.selectedImage = "";
    },
    makeFocus() {
      this.$refs.comment.$refs.input.focus();
    },
    onComment() {
      var formData = new FormData();
      if (this.selectedImage) {
        formData.append("file", this.selectedFile);
      }
      if (this.text) {
        formData.append("text", this.text);
      }
      formData.append("_id", this._id);
      this.loading = true;
      this.postComment(formData)
        .then(data => {
          this.loading = false;
          this.selectedFile = null;
          this.selectedImage = "";
          this.text = "";
          data.poster = this.profile._id;
          data.poster_type = this.profileType;
          data.poster_image = this.profile.thumbnail
            ? this.profile.thumbnail
            : this.profile.image;
          data.poster_name = this.profile.name
            ? this.profile.name
            : this.profile.first_name + " " + this.profile.last_name;
          this.onPosted(data);
          this.comments.push(data);
        })
        .catch(error => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    onUpdatedComment(data) {
      this.onPosted(data);
      this.comments = this.comments.map(c => (c._id != data._id ? c : data));
    },
    onDeletedComment(data) {
      this.comments = this.comments.filter(c => c._id !== data._id);
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    loadMoreCommentsAction() {
      this.loading = true;
      this.loadMoreComments({ _id: this._id, skip: this.comments.length })
        .then(data => {
          this.loading = false;
          this.comments = [...new Set([...this.comments, ...data])];
        })
        .catch(error => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    goToProfile(poster, type) {
      if (type == "Agency") {
        this.$router.push({
          name: "agency-profile-details",
          params: { id: poster }
        });
      } else if (type == "Volunteer") {
        this.$router.push({
          name: "volunteer-profile-details",
          params: { id: poster }
        });
      }
    }
  },
  mounted() {
    if (this._id) {
      this.loading = true;
      this.setEventCommentLoaded(false);
      this.getComments({ _id: this._id, selected: this.commentId })
        .then(data => {
          this.loading = false;
          this.comments = data;
          this.setEventCommentLoaded(true);
        })
        .catch(error => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    }
  }
};
</script>
<style scoped>
.comment-section {
  border: solid;
  border-radius: 20px;
  border-width: 1px;
  border-color: #c1c1c1;
  width: 100%;
}
.remove_button {
  position: absolute;
  top: 5px;
  right: 14px;
}
</style>
